
require('./node_modules/gsap/TweenMax.js');
require('./node_modules/gsap/TimelineMax.js');
require('./node_modules/gsap/EasePack.js');



// require("./js/GreenSocks/TweenMax.min.js");
// require("./js/GreenSocks/TimelineMax.min.js");
// require("./js/GreenSocks/easing/EasePack.min.js");

// require("./js/three.js");
// require("./js/THREE/libs/controls/OrbitControls.js");
// require("./js/THREE/libs/loaders/GLTFLoader.js");
